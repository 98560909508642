<script setup lang="ts">
import { InternalRouteName } from '~/constants/internal-route-name'
import { getStructuredDataScript } from '~/utils/structured-data'

interface ReservationLayoutProps {
    withReservationForm?: boolean
    displaySummary?: boolean
}

defineProps<ReservationLayoutProps>()

const { breadcrumbItems } = useReservationParams()

const reservationPageListWithBreadcrumb: (string | symbol)[] = [
    InternalRouteName.RESERVATION_ACCESSORIES,
    InternalRouteName.RESERVATION_OPTIONS,
    InternalRouteName.RESERVATION_LISTING,
    InternalRouteName.RESERVATION_SUMMARY,
    InternalRouteName.RESERVATION_REQUEST,
]

// TODO: update Breadcrumb for request page
const route = useRoute()
const hasRentalBreadcrumb = computed(() => {
    return route.name && reservationPageListWithBreadcrumb.includes(route.name)
})

const router = useRouter()
const { $joinSiteUrl } = useNuxtApp()
const structuredDataScript = getStructuredDataScript({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'numberOfItems': breadcrumbItems.value.length,
    'itemListElement': breadcrumbItems.value.map((item, index) => {
        const id = `page-${item?.route?.name?.toString() || item.name}`
        const relativePath = item?.link || (item.route && router.resolve(item.route)?.href)

        return {
            '@type': 'ListItem',
            'position': index + 1,
            'item': {
                '@id': id,
                'name': item?.name || item?.route?.name,
                'url': relativePath ? $joinSiteUrl(relativePath) : undefined,
            },
        }
    }),
})
</script>

<template>
    <div>
        <header :class="$style.header">
            <component :is="structuredDataScript" />
            <VTopBar>
                <LazyVReservationBreadcrumb
                    v-if="hasRentalBreadcrumb"
                    :steps="breadcrumbItems"
                    :class="$style.breadcrumb"
                />
            </VTopBar>
            <slot name="header" />
        </header>

        <main
            class="grid main-content-width--mobile-wider"
            :class="[$style.main, displaySummary && $style['main--condensed']]"
        >
            <slot />
            <LazyVReservationSummary
                v-if="displaySummary"
                :class="$style.aside"
            >
                <slot name="aside-button" />
            </LazyVReservationSummary>
        </main>
        <VFooter />
    </div>
</template>

<style lang="scss" module>
.breadcrumb {
    --v-reservation-breadcrumb-display: none;

    @include media('>=vl') {
        --v-reservation-breadcrumb-display: flex;
    }
}

.main {
    width: 100%;
    gap: rem(48) var(--gutter);
    padding-block: rem(24);

    > *:first-child {
        width: 100%;
        grid-column: 1 / -1;
    }

    &--condensed {
        align-items: flex-start;

        @include media('>=lg') {
            --grid-columns: 22;

            > *:first-child {
                grid-column: 1 / 14;
            }
        }

        @include media('>=vl') {
            > *:first-child {
                grid-column: 1 / 16;
            }
        }
    }
}

.aside {
    position: sticky;
    top: rem(24);
    padding: var(--spacing-md) var(--spacing-sm);
    border-radius: rem(16);
    background-color: #fff;
    grid-column: 1 / -1;

    @include media('>=lg') {
        padding: var(--spacing-md);
        grid-column: -10 / -1;
    }

    @include media('>=vl') {
        grid-column: -8 / -1;
    }

    > button:last-child {
        display: flex;
        margin-top: rem(22);
        margin-inline: auto;
    }
}
</style>
